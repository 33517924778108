import {nMonthsAgo} from "./utils/date";
import {DocumentGrouping, TimePeriodCode} from "./utils/type";

const {NODE_ENV} = process.env;

export const isDevelopment = NODE_ENV === "development";
export const API_URL = window.location.origin;

interface PerformanceCardTimePeriod {
  code: TimePeriodCode;
  type: "absolute" | "relative" | "both";
}

export const config = {
  general: {
    useMWR: false,
    useProfitAndLoss: false,
  },
  overviewPage: {
    showAdvisor: false,
    defaultTimePeriodCode: "CALYEAR-0" as TimePeriodCode,
    timePeriodCodes: [
      "MONTHS-1",
      "MONTHS-3",
      "MONTHS-6",
      "CALYEAR-0",
      "YEARS-1",
      "YEARS-3",
      "GIVEN",
    ] as TimePeriodCode[],
    performanceCardTimePeriodCodes: [
      {code: "GIVEN", type: "both"},
      {code: "CALYEAR-0", type: "both"}
    ] as PerformanceCardTimePeriod[]
  },
  portfolioPage: {
    showKID: false,
    showCashBalance: false,
    performanceCardTimePeriodCodes: [
      {code: "GIVEN", type: "both"},
      {code: "CALYEAR-0", type: "both"},
    ] as PerformanceCardTimePeriod[],
    depositAndWithdrawal: {
      showDepositAndWithdrawal: false,
      depositTag: "deposit-waiting",
      withdrawalTag: "withdrawal-waiting",
    },
    defaultTimePeriodCode: "CALYEAR-0" as TimePeriodCode,
    timePeriodCodes: [
      "MONTHS-1",
      "MONTHS-3",
      "MONTHS-6",
      "CALYEAR-0",
      "YEARS-1",
      "YEARS-3",
      "GIVEN",
    ] as TimePeriodCode[],
    portfolioList: {
      timePeriodCodes: [
        "MONTHS-1",
        "MONTHS-3",
        "MONTHS-6",
        "CALYEAR-0",
        "YEARS-1",
        "GIVEN",
      ] as TimePeriodCode[],
      portfolioStatusesToToggle: ["P", "C"],
    },
  },
  costsPage: {
    showCostsPage: true,
    timePeriods: [
      {code: "MONTHS-1" as TimePeriodCode, startDate: nMonthsAgo(1)},
      {code: "MONTHS-3" as TimePeriodCode, startDate: nMonthsAgo(3)},
      {code: "MONTHS-6" as TimePeriodCode, startDate: nMonthsAgo(6)},
      {
        code: "CALYEAR-0" as TimePeriodCode,
        startDate: new Date(new Date().getFullYear(), 0, 1),
      },
    ],
  },
  components: {
    securityDetails: {
      defaultTimePeriodCode: "CALYEAR-0" as TimePeriodCode,
      timePeriodCodes: [
        "MONTHS-1",
        "MONTHS-3",
        "CALYEAR-0",
        "YEARS-1",
        "YEARS-3",
        "YEARS-5"
      ] as TimePeriodCode[],
    },
  },
  tradingPage: {
    showTradingPage: false,
    tradeableSecuritiesTags: []
  },
  savingsPage: {
    showSavingsPage: false,
    minSavingsPlanAmount: 1000,
    minInvestmentAmount: 1000,
    maxNumberOfSecurities: 10,
  },
  securityDetailsPage: {
    showSecurityCosts: false,
  },
  documentsPage: {
    documentGrouping: [
      {
        pattern: /\bMNDrapport\b/i,
        label: "Månedsrapporter",
      },
      {
        pattern: /\b(skatt\d{4}|tax)\b/i,
        label: "Skattedokumenter",
      },
      {
        pattern: /\bavtaler\b/i,
        label: "Avtaledokumenter",
      },
    ] as DocumentGrouping[],
    reports: [
      {
        reportName: "DNB - Utvikling og historisk verdi",
        label: "Utvikling og historisk verdi"
      },
      {
        reportName: "Realized profits",
        label: "Realisert avkasting"
      },
      {
        reportName: "DNB - Bankkontoutdrag",
        label: "Bankkontoutdrag"
      }
    ]
  }
};
